// src/pages/blog/{microcmsBlog.blogId}.js
import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ShareButtons from "../../components/ShareButtons";
import "../../css/hero.css"

const newsDetailPage = ({ location, data }) => {
  const canonicalUrl = location.pathname

  return (
    <Layout>
      <Seo title={data.microcmsPortalProject.accountName.accountName} ogImage={data.microcmsPortalProject.thumbnail.url} />

      <section className="hero">
        <figure>
          <img src={data.microcmsPortalProject.thumbnail.url} alt="hero" style={{ width: "100%" }} />
        </figure>

        <div className="container max-w-5xl mx-auto p-8 catch">

          <div className="flex flex-wrap py-10">
            <div className="w-full p-8 bg-gray-400 rounded-lg bg-opacity-60">
              <h1 className="text-xl sm:text-4xl text-white font-bold">
                {data.microcmsPortalProject.title}
              </h1>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-base-main px-8 py-4">
        <div className="max-w-5xl mx-auto">

          <div className="flex flex-wrap">
            <div className="">
              <Link to={`/`}>
                <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <Link to={`/project/1`}>
                <button className="text-secondry-main text-sm underline">プロジェクトをみる</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <button className="text-base-dark text-sm non-underline">
                {data.microcmsPortalProject.title}
              </button>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-base-main mx-8">
        <div className="max-w-5xl mx-auto">
          <div className="w-full p-8 border-2 border-base-dark bg-white rounded-lg">
            <div className="pb-8 text-xl sm:text-2xl">
              このプロジェクトの立ち上げ団体
            </div>
            <div className="flex flex-wrap sm:justify-between">
              <div className="flex flex-col md:flex-row">
                <img width="160" src={data.microcmsPortalProject.accountName.thumbnail.url} alt="会社ロゴ" className="rounded-lg" />
                <div className="px-4 py-4 md:py-0">
                  <h1 className="text-xl sm:text-3xl text-base-dark font-bold leading-none mb-3">
                    {data.microcmsPortalProject.accountName.accountName}
                  </h1>
                </div>
              </div>
              <Link to={`/group-detail/${data.microcmsPortalProject.accountName.id}`}>
                <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-16 py-3">
                  団体をみる
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 mx-8">
        <div className="container max-w-5xl mx-auto">
          <div className="flex w-full p-8 border-2 border-base-dark bg-white rounded-lg">
            <div className="w-full">
              <div className="postbody font-medium"
                dangerouslySetInnerHTML={{
                  __html: `${data.microcmsPortalProject.body}`,
                }}
              />
              <div className="py-8">
                <ShareButtons title={data.microcmsPortalProject.title} url={canonicalUrl} />
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default newsDetailPage

export const query = graphql`
  query($id: String!) {
    microcmsPortalProject(id: { eq: $id }) {
      portalProjectId
      title
      accountName {
        id
        accountName
        thumbnail {
          url
        }
      }
      body
      category
      thumbnail {
        url
      }
    }
  }
`
